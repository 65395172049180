import './App.css';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


/* import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
 */

import { BrowserRouter as Router,  Routes,  Route} from "react-router-dom";
import Home from './pages/Home'
import About from './pages/About';
import AllProjets from './pages/AllProjets';
import AllServices from './pages/AllServices';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import NotFound from './pages/NotFound';

function App() {
  return (
    <div>
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/a-propos" element={<About />} />
          <Route path="/projets" element={<AllProjets />} />
          <Route path="/services" element={<AllServices />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="*" element={<NotFound />} /> {/* Route Not Found */}
        </Routes>
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
