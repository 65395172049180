import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import { API_BASE_URL, STORAGE_BASE_URL } from '../config/apiConfig';
import { ClipLoader } from 'react-spinners'; // Importer le spinner

function Services() {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/services`);
                setServices(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des services:', error);
                setError('Erreur lors de la récupération des services.');
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    if (loading) {
        return (
            <div className="text-center">
                <ClipLoader color="#027a8d" loading={loading} size={50} />
                <p>Chargement des services...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div>
            <h1 className="text-center mt-5">Nos Services</h1>
            <div className="slider-container mb-5">
                {services.length > 0 ? (
                    <Slider {...settings}>
                        {services.map((service, index) => (
                            <div className="pt-2 pb-2" key={index}>
                                <div className="service text-center shadow p-3 mb-5 rounded service-container" title={service.description}>
                                    <div className="image-container">
                                        <img src={`${STORAGE_BASE_URL}/${service.photo}`} className="p-2" style={{ width: '90px', height: '90px', objectFit: 'cover' }} alt={service.title} />
                                    </div>
                                    <h5>{service.title}</h5>
                                    <a href={service.lien} className="btn btn-primary" title={service.description}>En savoir plus</a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div className="col-12">
                        <p className="text-center">Aucun service disponible pour le moment.</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Services;
