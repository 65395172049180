import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function Navbar() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const closeMenu = () => {
        setIsMenuOpen(false);
    };

    return (
        <nav className="navbar navbar-expand-lg" style={{ backgroundColor: '#027a8d', position: 'sticky', top: 0, zIndex: 1000 }}>
            <div className="container">
                <Link className="navbar-brand text-white" to="/" onClick={closeMenu}><h3>LAYE-SOW</h3></Link>
                <button className="navbar-toggler menu-mobile" type="button" onClick={toggleMenu} data-bs-toggle="collapse" data-bs-target="#navbarColor01" aria-controls="navbarColor01" aria-expanded={isMenuOpen} aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon menu-mobile-icone"></span>
                </button>
                <div className={`collapse navbar-collapse justify-content-end ${isMenuOpen ? 'show' : ''}`} id="navbarColor01">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <Link className="nav-link text-white" to="/" onClick={closeMenu}>Accueil</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white" to="/projets" onClick={closeMenu}>Projets</Link> {/* Correction de /Projets à /projets */}
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white" to="/services" onClick={closeMenu}>Services</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white" to="/a-propos" onClick={closeMenu}>À propos</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link text-white" to="/contact" onClick={closeMenu}>Contact</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
