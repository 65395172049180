import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import axios from 'axios';
import { API_BASE_URL, STORAGE_BASE_URL } from '../config/apiConfig';
import { ClipLoader } from 'react-spinners'; // Importer le spinner

function Projets() {
    const [projets, setProjets] = useState([]);
    const [loading, setLoading] = useState(true); // État de chargement
    const [error, setError] = useState(''); // État d'erreur

    useEffect(() => {
        const fetchProjets = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/projects`);
                setProjets(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des projets:', error);
                setError('Erreur lors de la récupération des projets.'); // Définir le message d'erreur
            } finally {
                setLoading(false); // Marquer comme terminé, que ça réussisse ou échoue
            }
        };

        fetchProjets();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        rows: 2,
        autoplay: true,
        autoplaySpeed: 5000,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    if (loading) {
        return (
            <div className="text-center">
                <ClipLoader color="#027a8d" loading={loading} size={50} />
                <p>Chargement des projets...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div>
            <h1 className="text-center mt-5">~ Mes projets ~</h1>
            <div className="slider-container mb-5">
                {projets.length > 0 ? (
                    <Slider {...settings}>
                        {projets.map((projet) => (
                            <div className="card carte-projet" key={projet.id}>
                                <div className="de-item">
                                    <img src={`${STORAGE_BASE_URL}/${projet.photo}`} className="card-img-top" alt={projet.title} />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">{projet.title}</h5>
                                    <p className="card-text">{projet.description}</p>
                                    <a href={projet.lien} target='_blank' rel='noopener noreferrer' className="btn btn-primary">Voir le projet</a>
                                </div>
                            </div>
                        ))}
                    </Slider>
                ) : (
                    <div className="col-12">
                        <p className="text-center">Aucun projet disponible pour le moment.</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Projets;
