import React from 'react'
import Personne from '../components/Personne'
import Projets from '../components/Projets'
import Services from '../components/Services'
import backgroundImage1 from "../images/bg/landing-section7.jpg"

function Home() {
    return (
        <div style={{ backgroundImage: `url(${backgroundImage1})`, backgroundSize: 'cover' }}>
            <div className="container">
                <Personne/>
                <Services />
                <hr />
                <Projets />
            </div>          
        </div>
    )
}

export default Home
