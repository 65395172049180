import React from 'react';

function Footer() {
    return (
        <footer className="text-light py-4" style={{ backgroundColor: '#027a8d' }}>
            <div className="container">
                <div className="row">
                    <div className="col-md-8">
                        <h6 className="mb-3">Restez connecté(e) avec moi sur les réseaux sociaux pour découvrir encore plus d'infos </h6>
                    </div>
                    <div className="col-md-4">
                    <ul className="list-unstyled d-flex justify-content-center align-items-center">
                        <li className="me-3">
                            <a className="text-light" href="https://www.linkedin.com/in/abdoulaye-sow/" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-linkedin"></i>
                            </a>
                        </li>
                        <li className="me-3">
                            <a className="text-light" href="#github" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-github"></i>
                            </a>
                        </li>
                        <li className="me-3">
                            <a className="text-light" href="#twitter" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-twitter"></i>
                            </a>
                        </li>
                        <li className="me-3">
                            <a className="text-light" href="#instagram" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-instagram"></i>
                            </a>
                        </li>
                        <li className="me-3">
                            <a className="text-light" href="#facebook" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-facebook"></i>
                            </a>
                        </li>
                        <li className="me-3">
                            <a className="text-light" href="https://www.youtube.com/@jeunedev910" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-youtube"></i>
                            </a>
                        </li>
                    </ul>

                    </div>
                </div>
                <div className="">
                    <p className="text-center copyright">
                        &#169; {new Date().getFullYear()} Laye SOW. Tous droits réservés.
                    </p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
