import React from 'react'
import Service from '../components/dossierService/Service'

function AllServices() {
    return (
        <div>
            <div className="container">
            <h1 className="text-center mt-5">~ Mes Services ~</h1>
            <h3 className="text-center mb-4">Mes services sont là pour répondre à vos besoins.</h3>
                <Service />
            </div>
        </div>
    )
}

export default AllServices
