import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL, STORAGE_BASE_URL } from '../../config/apiConfig';
import { ClipLoader } from 'react-spinners'; // Importer le spinner

function Projet() {
    const [projets, setProjets] = useState([]);
    const [loading, setLoading] = useState(true); // État de chargement
    const [error, setError] = useState(''); // État d'erreur

    useEffect(() => {
        const getProjets = async () => {
            try {
                // Requête GET pour récupérer les projets
                const response = await axios.get(`${API_BASE_URL}/projects`);
                setProjets(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des projets:', error);
                setError('Erreur lors de la récupération des projets.'); // Définir le message d'erreur
            } finally {
                setLoading(false); // Marquer comme terminé, que ça réussisse ou échoue
            }
        };

        getProjets();
    }, []);

    if (loading) {
        return (
            <div className="text-center">
                <ClipLoader color="#027a8d" loading={loading} size={50} />
                <p>Chargement des projets...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div>
            <div className="row mb-5 mt-3">
                {projets.length > 0 ? (
                    projets.map(projet => (
                        <div className="col-md-4" key={projet.id}>
                            <div className="card carte-projet">
                                <div className="de-item">
                                    <img src={`${STORAGE_BASE_URL}/${projet.photo}`} className="card-img-top" alt={projet.title} />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title">{projet.title}</h5>
                                    <p className="card-text">{projet.description}</p>
                                    <a href={projet.lien} target='_blank' rel='noopener noreferrer' className="btn btn-primary">Voir le projet</a>
                                </div>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-12">
                        <p className="text-center">Aucun projet disponible pour le moment.</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Projet;
