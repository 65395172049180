import React from 'react'
import Projet from '../components/dossierProjet/Projet'

function AllProjets() {
    return (
        <div>
            <div className="container">
            <h1 className="text-center mt-5">~ Mes projets ~</h1>
                <Projet />
            </div>
        </div>
    )
}

export default AllProjets
