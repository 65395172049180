import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL, STORAGE_BASE_URL } from '../../config/apiConfig';
import { ClipLoader } from 'react-spinners'; // Exemple de spinner

export default function Service() {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        const fetchServices = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}/services`);
                setServices(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des services:', error);
                setError('Erreur lors de la récupération des services.');
            } finally {
                setLoading(false);
            }
        };

        fetchServices();
    }, []);

    if (loading) {
        return (
            <div className="text-center">
                <ClipLoader color="#027a8d" loading={loading} size={50} />
                <p>Chargement des services...</p>
            </div>
        );
    }

    if (error) {
        return (
            <div className="text-center">
                <p>{error}</p>
            </div>
        );
    }

    return (
        <div>
            <div className="row mb-5 mt-3">
                {services.length > 0 ? (
                    services.map((service) => (
                        <div className="col-md-4" key={service.id}>
                            <div className="service text-center shadow p-3 mb-5 rounded service-container">
                                <div className="image-container">
                                    <img
                                        src={`${STORAGE_BASE_URL}/${service.photo}`}
                                        className="p-2"
                                        style={{ width: '90px', height: '90px', objectFit: 'cover' }}
                                        alt={service.title}
                                    />
                                </div>
                                <h5>{service.title}</h5>
                                <a href={service.lien} className="btn btn-primary">En savoir plus</a>
                            </div>
                        </div>
                    ))
                ) : (
                    <div className="col-12 text-center">
                        <p>Aucun service disponible pour le moment.</p>
                    </div>
                )}
            </div>
        </div>
    );
}
