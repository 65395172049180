import React, { useState } from 'react';
import axios from 'axios';
import backgroundImage from "../../images/bg/bg-section-contact.jpg";
import { API_BASE_URL } from '../../config/apiConfig'

export default function Infos() {
    const redirigerVersTelephone = () => {
        window.location.href = 'tel:+221762245788';
    };
    
    const redirigerVersEmail = () => {
        window.location.href = 'mailto:jeunedev21@gmail.com';
    };

    const stylesLien = {
        textDecoration: 'none',
        color: 'black'
    };

    const stylesIcone = {
        fontSize: '24px'
    };

    const [formData, setFormData] = useState({
        prenom: '',
        nom: '',
        email: '',
        telephone: '',
        objet: '',
        description: ''
    });

    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}/contacts`, formData);
            if (response.status === 201) {
                setMessage('Message de contact envoyé avec succès !');
                // Réinitialiser les champs du formulaire
                setFormData({
                    prenom: '',
                    nom: '',
                    email: '',
                    telephone: '',
                    objet: '',
                    description: ''
                });
            }
        } catch (error) {
            console.error('Erreur lors de l\'envoi du message:', error);
            setMessage('Erreur lors de l\'envoi du message. Veuillez réessayer.');
        }
    };

    return (
        <div>
            <div style={{ backgroundImage: `url(${backgroundImage})`, backgroundSize: 'cover' }}>
                <div className="row mt-5 p-3">
                    <div className="col-sm-6">
                        <h1>Développez votre activité grâce à mon expertise</h1>
                    </div>
                    <div className="col-sm-6 p-3">
                        <p>Je suis disponible pour discuter de vos projets, répondre à vos questions ou simplement échanger des idées. N'hésitez pas à me contacter pour commencer une conversation.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-4">
                        <div className="info-container">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-2 icone-contact">
                                        <i className="fa fa-map-location-dot" style={stylesIcone} />
                                    </div>
                                    <div className="col-10">
                                        <h5 className="card-title">Votre Adresse</h5>
                                        <p className="card-text">Sénégal, Dakar, Guediawaye</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="info-container">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-2 icone-contact" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <i className="fa fa-phone" style={stylesIcone}></i>
                                    </div>
                                    <div className="col-10">
                                        <h5 className="card-title">Numéro de téléphone</h5>
                                        <p className="card-text">
                                            <a href="tel:+221779428554" className="card-text" style={stylesLien} onClick={redirigerVersTelephone}>+221 77 942 85 54</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="info-container">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-2 icone-contact" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <i className="fa fa-envelope" style={stylesIcone} />
                                    </div>
                                    <div className="col-10">
                                        <h5 className="card-title">Adresse e-mail</h5>
                                        <p className="card-text">
                                            <a href="mailto:abdoulayesow716@gmail.com" className="card-text" style={stylesLien} onClick={redirigerVersEmail}>abdoulayesow716@gmail.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container mt-5">
                    <h3 className="text-center mb-4">Contactez-moi</h3>
                    {message && <div className="alert alert-info">{message}</div>}
                    <form onSubmit={handleSubmit} className="row g-3">
                        <div className="col-md-6">
                            <label htmlFor="prenom" className="form-label">Prénom</label>
                            <input
                                type="text"
                                className="form-control"
                                id="prenom"
                                name="prenom"
                                value={formData.prenom}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="nom" className="form-label">Nom</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nom"
                                name="nom"
                                value={formData.nom}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="email" className="form-label">Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="telephone" className="form-label">Téléphone</label>
                            <input
                                type="tel"
                                className="form-control"
                                id="telephone"
                                name="telephone"
                                value={formData.telephone}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-12">
                            <label htmlFor="objet" className="form-label">Objet</label>
                            <input
                                type="text"
                                className="form-control"
                                id="objet"
                                name="objet"
                                value={formData.objet}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="col-12">
                            <label htmlFor="description" className="form-label">Description</label>
                            <textarea
                                className="form-control"
                                id="description"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                                rows="4"
                                required
                            ></textarea>
                        </div>
                        <div className="col-12 text-center mb-5">
                            <button type="submit" className="btn btn-primary mt-3 float-end" style={{ width: '30%' }}>Envoyer</button>
                        </div>
                    </form>
                </div>
            </div>
            <div className="col-12 mb-5 mt-3">
                {/* Balise iframe pour intégrer la carte */}
                <iframe
                    title="Localisation"
                    width="100%"
                    height="300"
                    frameBorder="0"
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                    style={{ border: 0 }}
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d61725.7222687443!2d-17.417033766305934!3d14.777047391559307!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xec10b47b6b25bcd%3A0x93fc379138ad34b2!2sGu%C3%A9diawaye%2C%20Dakar!5e0!3m2!1sfr!2ssn!4v1713028020519!5m2!1sfr!2ssn"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
}
