import React from 'react';
import '../css/About.css' // Assurez-vous de créer un fichier CSS pour le style
import cvPdf from "../cv/cv-laye-sow.pdf"
function About() {
    return (
        <div className="container">

            <div className="content">
                <h2>Présentation</h2>
                <p>
                    Bonjour ! Je m'appelle Abdoulaye SOW, un ingénieur informatique spécialisé dans le développement web et passionné par tout ce qui touche à la technologie et à la création numérique. Depuis plusieurs années, je m'efforce de transformer des idées innovantes en projets numériques concrets et efficaces.
                </p>
                <p>
                    Mon parcours professionnel m'a permis de développer une solide expertise dans plusieurs domaines tels que la programmation, la conception graphique, et la production vidéo. Mon objectif est toujours de créer des expériences utilisateur intuitives et captivantes.
                </p>

                <div className="skills">
                    <h2>Compétences</h2>
                    <ul>
                        <li>Développement web : HTML, CSS, tailwind css, JavaScript, PHP, Laravel, React </li>
                        <li>Graphisme : Adobe Photoshop, Illustrator, Adobe XD, Figma</li>
                        <li>Production vidéo : Adobe Premiere,Final Cut Pro,Filmora,Camtasia, After Effects</li>
                        <li>Webmastering : Gestion de sites web, SEO, Maintenance web</li>
                    </ul>
                </div>

                <div className="experience">
                    <h2>Expériences professionnelles</h2>
                    <ul>
                    <li>
                            <strong>Développeur web chez Mercatik Dakar</strong> (Depuis décembre 2023 - Présent)
                            <p>Création et gestion de sites et applications web avec le framework Laravel. Création et gestion de sites web à l'aide de CMS tels que WordPress. Optimisation du référencement naturel (SEO) pour améliorer la visibilité des sites web.</p>
                        </li>
                        <li>
                            <strong>Développeur web, Webmaster, Graphiste et Monteur vidéo chez Reselform Dakar</strong> (De novembre 2022 à novembre 2023)
                            <p>Responsable du développement web, du graphisme, de la captation et du montage vidéo.</p>
                        </li>
                        <li>
                            <strong>Développeur web, Graphiste, cadreur et monteur chez CCN Groupe Dakar</strong> (De 2020 à 2022)
                            <p>Formateur en HTML, CSS et WordPress. Création de streaming pour les réseaux sociaux, cadrage et montage vidéo.</p>
                        </li>

                        <li>
                            <strong>Assistant Formateur chez CCN Groupe Dakar</strong> (Depuis 2020)
                            <p>Formateur en HTML, CSS et WordPress. Assistant Formateur en développement web (HTML5, CSS3, JavaScript, WordPress).</p>
                        </li>
                        <li>
                            <strong>Stagiaire en développement web chez CCN Groupe Dakar</strong> ( 2019 - 2020)
                            <p>Développement web et maintenance de sites web.</p>
                        </li>
                        <li>
                            <strong>Stagiaire en développement web chez New Africa Consulting Dakar</strong> (2018 - 2019)
                            <p>Développement web et maintenance de sites web.</p>
                        </li>
                        <li>
                            <strong>Stagiaire en développement web chez aCAN Group Dakar</strong> (2017 - 2018)
                            <p>Création et administration de sites web, gestion des réseaux sociaux et création de supports de communication.</p>
                        </li>
                        <li>
                            <strong>Graphiste indépendant</strong> (2018 - présent)
                            <p>Création de supports visuels pour des campagnes publicitaires, des sites web, et des identités visuelles.</p>
                        </li>
                        <li>
                            <strong>Vidéaste freelance</strong> (2013 - Présent)
                            <p>Production et montage de vidéos promotionnelles, tutoriels, et contenu vidéo pour les réseaux sociaux.</p>
                        </li>
                    </ul>
                </div>

                <div className="download-cv">
                    <a href={cvPdf} target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-file-pdf"></i> Télécharger mon CV
                    </a>
                </div>
            </div>
        </div>
    );
}

export default About;
