import React from 'react'

import paby from "../images/pabylaye.jpg"
import cvPdf from "../cv/cv-laye-sow.pdf"
import { ReactTyped } from 'react-typed'


function Personne() {
    return (
        <div>
            <div className="container container-custom mb-3 mt-3">
                <div className="row align-items-center g-4">
                    <div className="col-md-6 text-center">
                        <img src={paby} alt="" className="img-fluid jeuneImage" />
                    </div>
                    <div className="col-md-6 text-white">
                    <div className="bg-noir-transparent p-3">
                        <h3 className="mb-3 text-center">˜”*°•. Abdoulaye SOW .•°*”˜</h3>
                        <h4 className="mb-3">Je suis ☞ <span className="mr-3"></span>
 
                            <ReactTyped 
                                strings={["Développeur web", "Graphiste Designer", "Vidéaste", "Webmaster"]}
                                typeSpeed={170}
                                backSpeed={100}
                                loop
                            />
                        </h4>
                        <h5 className="">💻🎨 Ingénieur informatique passionné par le développement web et créatif dans l'âme. Je transforme vos idées en expériences numériques captivantes. Découvrez mes créations et embarquez dans un voyage digital avec moi ! ⤴</h5>
                        </div>
                        <div className="p-3 mb-0 d-flex justify-content-center">
                            <a href={cvPdf} target="_blank" rel="noopener noreferrer" className="btn mon-cv w-100">
                                <i className="fa fa-file-pdf"></i> Télécharger mon CV
                            </a>
                        </div>
                    </div>

                </div>
                
            </div>
        </div>
    )
}

export default Personne
