import React from 'react'
import ErrorImage from '../images/404Error-rafiki.svg';

export default function NotFound() {
    
  return (
    <div>
        <div style={{ textAlign: 'center', padding: '5px' }}>
            <img src={ErrorImage} alt="Page non trouvée" style={{ width: '100%', maxWidth: '30%', marginTop: '20px' }} />
            <p>La page que vous recherchez n'existe pas.</p>
        </div>
    </div>
  )
}

