import React from 'react'
import Infos from '../components/contact/Infos'

function Contact() {
    return (
        <div>
            <div className="container">
                <Infos />
            </div>
        </div>
    )
}

export default Contact
